import React, { useState } from 'react';
import styled from "styled-components";
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default function Subscribe(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = event => {
    event.preventDefault()
    addToMailchimp(email)
      .then(data => {
        setMessage("Thank you! You are subscribed.")
      })
      .catch(() => {
      })
  }

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  }

  return (
    <div>
      <div style={{ textAlign: "center", paddingBottom: "10px", fontWeight: "normal" }}>{props.title ? props.title : null}</div>
      <HeaderForm onSubmit={handleSubmit}>
        <HeaderInput
          placeholder="Email"
          onChange={handleEmailChange}
          type="email"
          name="EMAIL"
          required
          id="mce-EMAIL" />
        <HeaderButton>{props.buttonText ? props.buttonText : "Learn More"}</HeaderButton>
      </HeaderForm>
      {message !== '' ? (
        <div style={{ color: "blueviolet", textAlign: "center" }}>{message}</div>
      ) : null}
    </div>
  );
}

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 16px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`
const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 50%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`